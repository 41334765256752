// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-a-1-js": () => import("./../../../src/pages/A1.js" /* webpackChunkName: "component---src-pages-a-1-js" */),
  "component---src-pages-a-coming-soon-js": () => import("./../../../src/pages/A_ComingSoon.js" /* webpackChunkName: "component---src-pages-a-coming-soon-js" */),
  "component---src-pages-b-0-js": () => import("./../../../src/pages/B0.js" /* webpackChunkName: "component---src-pages-b-0-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */)
}

