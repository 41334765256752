module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"家庭健康長・財團法人千禧之愛健康基金會 - 實踐3D健康生活，遠離代謝症候群畫","short_name":"家庭健康長・財團法人千禧之愛健康基金會 - 實踐3D健康生活，遠離代謝症候群畫","start_url":"/","background_color":"#F3EDDC","theme_color":"#FFF41F","display":"standalone","icon":"src/images/fav_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"600f3d30d56ac923a5cdc0f7302c851e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171821270-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
